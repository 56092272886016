import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/login"),
  },
  {
    path: '/',
    component: () => import('@/views/home'),
    children: [
      { path: '/home', component: () => import('@/views/layout') },
      { path: '/my', component: () => import('@/views/my') }
    ]
  }, 
  {
    path: "/userlist",
    component: () => import("@/views/user"),
  },
  {
    path: "/adduser",
    component: () => import("@/views/user/add"),
  },
  {
    path: "/paylist",
    component: () => import("@/views/pay"),
  },
  {
    path: "/rechargelist",
    component: () => import("@/views/recharge"),
  },
  {
    path: "/accountlist",
    component: () => import("@/views/account"),
  },
  {
    path: "/restrict",
    component: () => import("@/views/restrict"),
  },
  {
    path: "/school",
    component: () => import("@/views/school"),
  },
  {
    path: "/help",
    component: () => import("@/views/help"),
  },
  {
    path: "/about",
    component: () => import("@/views/about"),
  },
  {
    path: "/bill",
    component: () => import("@/views/bill"),
  },
  {
    path: "/bannerInfo",
    component: () => import("@/views/bannerInfo"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
