import Vue from "vue";
import {
  Button,
  Picker,
  DatetimePicker,
  Tabbar,
  TabbarItem,
  Form,
  Field,
  Toast,
  Dialog,
  Tab,
  Tabs,
  Cell,
  List,
  NavBar,
  NoticeBar,
  Grid,
  GridItem,
  Swipe,
  Popup,
  SwipeItem,
  Empty,
  Divider,
  Loading,
} from "vant";

Vue.use(Dialog);
Vue.use(Loading);
Vue.use(List);
Vue.use(Popup);
Vue.use(Empty);
Vue.use(DatetimePicker);
Vue.use(Button);
Vue.use(Picker);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(List);
Vue.use(NoticeBar);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Divider);
