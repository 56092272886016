import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "lib-flexible/flexible";
import "@/utlis/vant-ul";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
